import React from 'react'

/* Components */
import BannerPicture, { BannerPictureProps } from '../common/BannerPicture'
import SwitchLink from '@components/seo/SwitchLink'

/* Helpers */
import { convertPositionValue } from '@lib/strapi/helpers'
import { getImgixURLAtWidth } from '@helpers/imgixHelper'

/* Styles */
import BrandBannerStyles from './BrandBanner.module.scss'

/* Local Types */
interface BrandBannerPosition {
	top?: number
	bottom?: number
	left?: number
	right?: number
}

interface BrandBannerInfo {
	picture: BannerPictureProps
	ctaTitle: string
	ctaTitleColor?: string
	ctaBackgroundColor?: string
	ctaBorderColor?: string
	brandLogoSrc: string
	brandLogoHeight: number
	brandLogoWidth: number
	brandLogoAlt: string
	desktopCtaPosition: BrandBannerPosition
	desktopBrandLogoPosition: BrandBannerPosition
	mobileCtaPosition: BrandBannerPosition
	mobileBrandLogoPosition: BrandBannerPosition
	desktopHorizontalPaddingCTA: number
	mobileHorizontalPaddingCTA: number
	desktopBrandLogoContainerWidth: number
	mobileBrandLogoContainerWidth: number
	ctaLinkHref: string
}

const BrandBanner = ({ info }: { info: BrandBannerInfo }) => {
	/* Local Components */
	const PrimaryLink = ({
		horizontalPadding,
	}: {
		horizontalPadding: number
	}) => (
		<SwitchLink
			href={info.ctaLinkHref}
			style={{
				paddingLeft: `${horizontalPadding}px`,
				paddingRight: `${horizontalPadding}px`,
				borderColor: info.ctaBorderColor || '#fff',
				color: info.ctaTitleColor || '#fff',
				backgroundColor: info.ctaBackgroundColor || '#000',
			}}
			className={`${BrandBannerStyles['cta-button']} ${BrandBannerStyles['cta-button__text']}`}
		>
			{info.ctaTitle}
			{info.brandLogoAlt && (
				<span className={`${BrandBannerStyles['seo']}`}>
					{' '}
					- {info.brandLogoAlt}
				</span>
			)}
		</SwitchLink>
	)

	const DesktopCTA = (
		<div
			className={`${BrandBannerStyles['desktop-cta']}`}
			style={{
				top: convertPositionValue(info.desktopCtaPosition.top),
				bottom: convertPositionValue(info.desktopCtaPosition.bottom),
				left: convertPositionValue(info.desktopCtaPosition.left),
				right: convertPositionValue(info.desktopCtaPosition.right),
			}}
		>
			{PrimaryLink({ horizontalPadding: info.desktopHorizontalPaddingCTA })}
		</div>
	)

	const MobileCTA = (
		<div
			className={`${BrandBannerStyles['mobile-cta']}`}
			style={{
				top: convertPositionValue(info.mobileCtaPosition.top),
				bottom: convertPositionValue(info.mobileCtaPosition.bottom),
				left: convertPositionValue(info.mobileCtaPosition.left),
				right: convertPositionValue(info.mobileCtaPosition.right),
			}}
		>
			{PrimaryLink({ horizontalPadding: info.mobileHorizontalPaddingCTA })}
		</div>
	)

	const DesktopBrandLogo = (
		<div
			className={`${BrandBannerStyles['desktop-brand-logo']}`}
			style={{
				top: convertPositionValue(info.desktopBrandLogoPosition.top),
				bottom: convertPositionValue(info.desktopBrandLogoPosition.bottom),
				left: convertPositionValue(info.desktopBrandLogoPosition.left),
				right: convertPositionValue(info.desktopBrandLogoPosition.right),
			}}
		>
			<img
				src={getImgixURLAtWidth(
					info.brandLogoSrc,
					info.desktopBrandLogoContainerWidth
				)}
				alt={info.brandLogoAlt}
				height={info.brandLogoHeight}
				width={info.brandLogoWidth}
				loading={'lazy'}
				style={{
					objectFit: 'contain',
					width: `${info.desktopBrandLogoContainerWidth}px`,
					height: 'auto',
				}}
			/>
		</div>
	)

	const MobileBrandLogo = (
		<div
			className={`${BrandBannerStyles['mobile-brand-logo']}`}
			style={{
				top: convertPositionValue(info.mobileBrandLogoPosition.top),
				bottom: convertPositionValue(info.mobileBrandLogoPosition.bottom),
				left: convertPositionValue(info.mobileBrandLogoPosition.left),
				right: convertPositionValue(info.mobileBrandLogoPosition.right),
			}}
		>
			<img
				src={getImgixURLAtWidth(
					info.brandLogoSrc,
					info.mobileBrandLogoContainerWidth
				)}
				alt={info.brandLogoAlt}
				height={info.brandLogoHeight}
				width={info.brandLogoWidth}
				style={{
					objectFit: 'contain',
					width: `${info.mobileBrandLogoContainerWidth}px`,
					height: 'auto',
				}}
			/>
		</div>
	)

	return (
		<div className={`${BrandBannerStyles['container']}`}>
			<div className={`${BrandBannerStyles['image-container']}`}>
				<BannerPicture
					pictureDesktop={info.picture.pictureDesktop}
					pictureMobile={info.picture.pictureMobile}
					alt={info.picture.alt}
				/>
			</div>
			{DesktopCTA}
			{DesktopBrandLogo}
			{MobileCTA}
			{MobileBrandLogo}
		</div>
	)
}

export default BrandBanner
