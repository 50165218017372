import {
	StrapiArticlesBannerSlide,
	StrapiBrandBannerItem,
	StrapiBrandButton,
	StrapiBrandsBannerItem,
	StrapiHeroBannerSlide,
	StrapiImageBannerItem,
	StrapiIntroBannerItem,
	StrapiPlayBannerItem,
	StrapiStoresBannerSlide,
	StrapiVideoBannerSlide,
	StrapiNewsBannerItems,
	StrapiCollectionBannerItem,
	StrapiIframeBannerItem,
	StrapiInfoBannerItem,
} from '@govberg/flageollet-types'

import { getExpectedImgixURL } from '../helpers/imgixHelper'

export const replaceEscapedNewlines = (text: string) => {
	return text.replace(/\\n/g, '\n')
}

export const convertPositionValue = (value: unknown) => {
	if (value === null || value === undefined) {
		return undefined
	}

	if (typeof value === 'number') {
		return value
	}

	return undefined
}

export const convertToHeroBanner = (
	strapiHeroBannerSlides: StrapiHeroBannerSlide[]
) => {
	return (
		strapiHeroBannerSlides.map((slide) => {
			const alt = slide.desktopImage.alternativeText || ''
			const pictureDesktop = {
				height: slide.desktopImage.height || 0,
				width: slide.desktopImage.width || 0,
				src: getExpectedImgixURL(slide.desktopImage.url),
				alt: slide.desktopImage.alternativeText || '',
			}

			const pictureMobile = {
				height: slide.mobileImage.height || 0,
				width: slide.mobileImage.width || 0,
				src: getExpectedImgixURL(slide.mobileImage.url),
				alt,
			}

			let mainItem
			if (slide.mainType) {
				mainItem = {
					title: slide.mainType.title,
					titleAlignment: slide.mainType.titleAlignment || undefined,
					titleColor: slide.mainType.titleColor || '',
					ctaAlignment: slide.mainType.ctaAlignment || undefined,
					ctaText: slide.mainType.ctaText,
					ctaTextColor: slide.mainType.ctaTextColor || '',
					ctaLinkHref: slide.mainType.ctaLinkHref,
					ctaBackgroundColor: slide.mainType.ctaBackgroundColor || '',
					ctaBorderColor: slide.mainType.ctaBorderColor || '',
					position: {
						top: convertPositionValue(slide.mainType.position.top),
						right: convertPositionValue(slide.mainType.position.right),
						bottom: convertPositionValue(slide.mainType.position.bottom),
						left: convertPositionValue(slide.mainType.position.left),
					},
				}
			}

			let internalItem
			if (slide.internalType) {
				internalItem = {
					title: slide.internalType.title,
					titleColor: slide.internalType.titleColor || '',
					actionCTAs: slide.internalType.actionCTAs.map((cta) => {
						return {
							text: cta.text,
							href: cta.linkHref,
							type: cta.type,
						}
					}),
				}
			}

			return {
				id: slide.id,
				picture: { pictureDesktop, pictureMobile, alt },
				mainItem,
				internalItem,
				sliderTextColor: slide.sliderTextColor || '',
				sliderSelectedColor: slide.sliderSelectedColor || '',
			}
		}) || []
	)
}

export const convertToIntroBanner = (
	strapiIntroBanner: StrapiIntroBannerItem
) => {
	const mainAlt = strapiIntroBanner.pictureDesktop.alternativeText || ''

	const pictureDesktop = {
		height: strapiIntroBanner.pictureDesktop.height || 0,
		width: strapiIntroBanner.pictureDesktop.width || 0,
		src: getExpectedImgixURL(strapiIntroBanner.pictureDesktop.url),
		alt: strapiIntroBanner.pictureDesktop.alternativeText || '',
	}

	const pictureMobile = {
		height: strapiIntroBanner.pictureMobile.height || 0,
		width: strapiIntroBanner.pictureMobile.width || 0,
		src: getExpectedImgixURL(strapiIntroBanner.pictureMobile.url),
		alt: strapiIntroBanner.pictureMobile.alternativeText || '',
	}

	return {
		title: strapiIntroBanner.title,
		main: {
			pictureDesktop,
			pictureMobile,
			alt: mainAlt,
		},
		bullets: strapiIntroBanner.bullets.map((bullet) => {
			const bulletAlt = bullet.pictureDesktop.alternativeText || ''

			const bulletPictureDesktop = {
				height: bullet.pictureDesktop.height || 0,
				width: bullet.pictureDesktop.width || 0,
				src: getExpectedImgixURL(bullet.pictureDesktop.url),
				alt: bullet.pictureDesktop.alternativeText || '',
			}

			const bulletPictureMobile = {
				height: bullet.pictureMobile.height || 0,
				width: bullet.pictureMobile.width || 0,
				src: getExpectedImgixURL(bullet.pictureMobile.url),
				alt: bullet.pictureMobile.alternativeText || '',
			}

			return {
				headline: bullet.headline,
				text: bullet.description,
				pictureDesktop: bulletPictureDesktop,
				pictureMobile: bulletPictureMobile,
				alt: bulletAlt,
			}
		}),
	}
}

export const convertToVideoBanner = (
	strapiVideoBannerSlides: StrapiVideoBannerSlide[]
) => {
	return strapiVideoBannerSlides.map((slide) => {
		/* Expected Desktop Sources */
		const { videoSrc, videoWebm } = slide
		let { videoSrcMobile, videoWebmMobile } = slide

		/* For localhost debugging only */
		/*
            const debugDesktopSrc = {
                webm: {
                    url: 'https://the1916company.imgix.net/live_shopping/09-27-24_live_shopping_homepage_banner_desktop.webm',
                    mime: 'video/webm',
                },
                mp4: {
                    url: 'https://the1916company.imgix.net/live_shopping/09-27-24_live_shopping_homepage_banner_desktop.mp4',
                    mime: 'video/mp4',
                },
            }
            const debugMobileSrc = {
                webm: {
                    url: 'https://the1916company.imgix.net/live_shopping/09-27-24_live_shopping_homepage_banner_mobile.webm',
                    mime: 'video/webm',
                },
                mp4: {
                    url: 'https://the1916company.imgix.net/live_shopping/09-27-24_live_shopping_homepage_banner_mobile.mp4',
                    mime: 'video/mp4',
                },
            }

            slide.title =
                'Watch weekly Interactive, shoppable livestreams with Tim Mosso and The 1916 Company.'
            slide.linkName = 'EXPLORE'
            slide.linkHref = '/live/'
            slide.bgColor = '#000'
            slide.objectFit = 'contain'
        */

		const debugDesktopSrc = false
		const debugMobileSrc = false

		/* Fallback for excluded mobile sources */
		if (!videoSrcMobile?.url) {
			videoSrcMobile = videoSrc
		}
		if (!videoWebmMobile?.url) {
			videoWebmMobile = videoWebm
		}

		return {
			id: slide.id,
			title: slide.title || '',
			linkName: slide.linkName || '',
			linkHref: slide.linkHref || '',
			bgColor: slide.bgColor || '',
			objectFit: slide.objectFit || 'cover',
			desktopSrc: debugDesktopSrc
				? debugDesktopSrc
				: {
						webm: videoWebm?.url
							? {
									url: videoWebm.url,
									mime: videoWebm.mime,
							  }
							: undefined,
						mp4: videoSrc?.url
							? {
									url: videoSrc.url,
									mime: videoSrc.mime,
							  }
							: undefined,
				  },
			mobileSrc: debugMobileSrc
				? debugMobileSrc
				: {
						webm: videoWebmMobile?.url
							? {
									url: videoWebmMobile.url,
									mime: videoWebmMobile.mime,
							  }
							: undefined,
						mp4: videoSrcMobile?.url
							? {
									url: videoSrcMobile.url,
									mime: videoSrcMobile.mime,
							  }
							: undefined,
				  },
			titleColor: slide.titleColor || '',
			ctaColor: slide.ctaColor || '',
			sliderItemColor: slide.sliderItemColor || '',
			showSliderItem: slide.showSliderItem || false,
			allowAudio: slide.allowAudio || false,
		}
	})
}

export const convertToPlayBanner = (strapiPlayBanner: StrapiPlayBannerItem) => {
	const mainAlt = strapiPlayBanner.pictureDesktop.alternativeText || ''

	const pictureDesktop = {
		height: strapiPlayBanner.pictureDesktop.height || 0,
		width: strapiPlayBanner.pictureDesktop.width || 0,
		src: getExpectedImgixURL(strapiPlayBanner.pictureDesktop.url),
		alt: strapiPlayBanner.pictureDesktop.alternativeText || '',
	}

	const pictureMobile = {
		height: strapiPlayBanner.pictureMobile.height || 0,
		width: strapiPlayBanner.pictureMobile.width || 0,
		src: getExpectedImgixURL(strapiPlayBanner.pictureMobile.url),
		alt: strapiPlayBanner.pictureMobile.alternativeText || '',
	}

	return {
		title: strapiPlayBanner.title || '',
		picture: {
			pictureDesktop,
			pictureMobile,
			alt: mainAlt,
		},
		linkName: strapiPlayBanner.ctaLinkText || '',
		linkHref: strapiPlayBanner.ctaLinkHref || '',
		titleColor: strapiPlayBanner.titleColor || '',
		ctaColor: strapiPlayBanner.ctaTextColor || '',
		ctaBackgroundColor: strapiPlayBanner.ctaBackgroundColor || '',
		playButtonHref: strapiPlayBanner.playButtonHref,
	}
}

export const convertToImageBanner = (
	strapiImageBannerItem: StrapiImageBannerItem
) => {
	const mainAlt = strapiImageBannerItem.pictureDesktop.alternativeText || ''

	const pictureDesktop = {
		height: strapiImageBannerItem.pictureDesktop.height || 0,
		width: strapiImageBannerItem.pictureDesktop.width || 0,
		src: getExpectedImgixURL(strapiImageBannerItem.pictureDesktop.url),
		alt: strapiImageBannerItem.pictureDesktop.alternativeText || '',
	}

	const pictureMobile = {
		height: strapiImageBannerItem.pictureMobile.height || 0,
		width: strapiImageBannerItem.pictureMobile.width || 0,
		src: getExpectedImgixURL(strapiImageBannerItem.pictureMobile.url),
		alt: strapiImageBannerItem.pictureMobile.alternativeText || '',
	}

	return {
		title: strapiImageBannerItem.title || '',
		picture: {
			pictureDesktop,
			pictureMobile,
			alt: mainAlt,
		},
		titleColor: strapiImageBannerItem.titleColor || '',
	}
}

export const convertToBrandBanner = (
	strapiBrandBannerItem: StrapiBrandBannerItem
) => {
	const mainAlt = strapiBrandBannerItem.pictureDesktop.alternativeText || ''

	const pictureDesktop = {
		height: strapiBrandBannerItem.pictureDesktop.height || 0,
		width: strapiBrandBannerItem.pictureDesktop.width || 0,
		src: getExpectedImgixURL(strapiBrandBannerItem.pictureDesktop.url),
		alt: strapiBrandBannerItem.pictureDesktop.alternativeText || '',
	}

	const pictureMobile = {
		height: strapiBrandBannerItem.pictureMobile.height || 0,
		width: strapiBrandBannerItem.pictureMobile.width || 0,
		src: getExpectedImgixURL(strapiBrandBannerItem.pictureMobile.url),
		alt: strapiBrandBannerItem.pictureMobile.alternativeText || '',
	}

	return {
		ctaTitle: strapiBrandBannerItem.ctaTitle,
		picture: {
			pictureDesktop,
			pictureMobile,
			alt: mainAlt,
		},
		ctaTitleColor: strapiBrandBannerItem.ctaTitleColor || '',
		ctaBackgroundColor: strapiBrandBannerItem.ctaBackgroundColor || '',
		ctaBorderColor: strapiBrandBannerItem.ctaBorderColor || '',
		brandLogoHeight: strapiBrandBannerItem.brandLogo.height || 0,
		brandLogoWidth: strapiBrandBannerItem.brandLogo.width || 0,
		brandLogoSrc: getExpectedImgixURL(strapiBrandBannerItem.brandLogo.url),
		brandLogoAlt: strapiBrandBannerItem.brandLogo.alternativeText || '',
		desktopCtaPosition: strapiBrandBannerItem.desktopCtaPosition,
		desktopBrandLogoPosition: strapiBrandBannerItem.desktopBrandLogoPosition,
		mobileCtaPosition: strapiBrandBannerItem.mobileCtaPosition,
		mobileBrandLogoPosition: strapiBrandBannerItem.mobileBrandLogoPosition,
		desktopHorizontalPaddingCTA:
			strapiBrandBannerItem.desktopHorizontalPaddingCTA || 64,
		mobileHorizontalPaddingCTA:
			strapiBrandBannerItem.mobileHorizontalPaddingCTA || 24,
		desktopBrandLogoContainerWidth:
			strapiBrandBannerItem.desktopBrandLogoContainerWidth || 176,
		mobileBrandLogoContainerWidth:
			strapiBrandBannerItem.mobileBrandLogoContainerWidth || 95,
		ctaLinkHref: strapiBrandBannerItem.ctaLinkHref,
	}
}

export const convertToBrandsBanner = (
	strapiBrandBannerItems: StrapiBrandsBannerItem[],
	strapiBrandButtons: StrapiBrandButton[]
) =>
	strapiBrandBannerItems.map((strapiBrandBannerItem, index) => {
		const mainAlt = strapiBrandBannerItem.pictureDesktop.alternativeText || ''

		const pictureDesktop = {
			height: strapiBrandBannerItem.pictureDesktop.height || 0,
			width: strapiBrandBannerItem.pictureDesktop.width || 0,
			src: getExpectedImgixURL(strapiBrandBannerItem.pictureDesktop.url),
			alt: strapiBrandBannerItem.pictureDesktop.alternativeText || '',
		}

		const pictureMobile = {
			height: strapiBrandBannerItem.pictureMobile.height || 0,
			width: strapiBrandBannerItem.pictureMobile.width || 0,
			src: getExpectedImgixURL(strapiBrandBannerItem.pictureMobile.url),
			alt: strapiBrandBannerItem.pictureMobile.alternativeText || '',
		}

		return {
			id: strapiBrandBannerItem.id,
			buttonBrandLogoHeight: strapiBrandButtons[index]?.brandLogo.height || 0,
			buttonBrandLogoWidth: strapiBrandButtons[index]?.brandLogo.width || 0,
			buttonBrandLogoSrc: getExpectedImgixURL(
				strapiBrandButtons[index]?.brandLogo.url || ''
			),
			buttonBrandLogoAlt:
				strapiBrandButtons[index]?.brandLogo.alternativeText || '',
			ctaTitle: strapiBrandBannerItem.ctaTitle,
			picture: {
				pictureDesktop,
				pictureMobile,
				alt: mainAlt,
			},
			ctaTitleColor: strapiBrandBannerItem.ctaTitleColor || '',
			ctaBackgroundColor: strapiBrandBannerItem.ctaBackgroundColor || '',
			ctaBorderColor: strapiBrandBannerItem.ctaBorderColor || '',
			brandLogoHeight: strapiBrandBannerItem.brandLogo.height || 0,
			brandLogoWidth: strapiBrandBannerItem.brandLogo.width || 0,
			brandLogoSrc: getExpectedImgixURL(strapiBrandBannerItem.brandLogo.url),
			brandLogoAlt: strapiBrandBannerItem.brandLogo.alternativeText || '',
			desktopCtaPosition: strapiBrandBannerItem.desktopCtaPosition,
			desktopBrandLogoPosition: strapiBrandBannerItem.desktopBrandLogoPosition,
			mobileCtaPosition: strapiBrandBannerItem.mobileCtaPosition,
			mobileBrandLogoPosition: strapiBrandBannerItem.mobileBrandLogoPosition,
			desktopHorizontalPaddingCTA:
				strapiBrandBannerItem.desktopHorizontalPaddingCTA || 64,
			mobileHorizontalPaddingCTA:
				strapiBrandBannerItem.mobileHorizontalPaddingCTA || 24,
			desktopBrandLogoContainerWidth:
				strapiBrandBannerItem.desktopBrandLogoContainerWidth || 176,
			mobileBrandLogoContainerWidth:
				strapiBrandBannerItem.mobileBrandLogoContainerWidth || 95,
			ctaLinkHref: strapiBrandBannerItem.ctaLinkHref,
			// Brand Button
			desktopButtonLogoContainerWidth:
				strapiBrandButtons[index]?.desktopLogoWidth || 0,
			desktopButtonSelectedBarWidth:
				strapiBrandButtons[index]?.desktopSelectedBarWidth || 0,
			mobileButtonLogoContainerWidth:
				strapiBrandButtons[index]?.mobileLogoWidth || 0,
			mobileButtonSelectedBarWidth:
				strapiBrandButtons[index]?.mobileSelectedBarWidth || 0,
			desktopButtonPosition: {
				top: convertPositionValue(
					strapiBrandButtons[index]?.desktopPositionTop
				),
				right: convertPositionValue(
					strapiBrandButtons[index]?.desktopPositionRight
				),
				bottom: convertPositionValue(
					strapiBrandButtons[index]?.desktopPositionBottom
				),
				left: convertPositionValue(
					strapiBrandButtons[index]?.desktopPositionLeft
				),
			},
			mobileButtonPosition: {
				top: convertPositionValue(strapiBrandButtons[index]?.mobilePositionTop),
				right: convertPositionValue(
					strapiBrandButtons[index]?.mobilePositionRight
				),
				bottom: convertPositionValue(
					strapiBrandButtons[index]?.mobilePositionBottom
				),
				left: convertPositionValue(
					strapiBrandButtons[index]?.mobilePositionLeft
				),
			},
		}
	})

export const convertToArticlesBanner = (
	strapiArticlesBannerSlides: StrapiArticlesBannerSlide[]
) =>
	strapiArticlesBannerSlides.map((strapiArticlesBannerSlide) => {
		const mainAlt =
			strapiArticlesBannerSlide.desktopPicture.alternativeText || ''

		const pictureDesktop = {
			height: strapiArticlesBannerSlide.desktopPicture.height || 0,
			width: strapiArticlesBannerSlide.desktopPicture.width || 0,
			src: getExpectedImgixURL(strapiArticlesBannerSlide.desktopPicture.url),
			alt: strapiArticlesBannerSlide.desktopPicture.alternativeText || '',
		}

		const pictureMobile = {
			height: strapiArticlesBannerSlide.mobilePicture.height || 0,
			width: strapiArticlesBannerSlide.mobilePicture.width || 0,
			src: getExpectedImgixURL(strapiArticlesBannerSlide.mobilePicture.url),
			alt: strapiArticlesBannerSlide.mobilePicture.alternativeText || '',
		}

		return {
			id: strapiArticlesBannerSlide.id,
			ctaTitle: strapiArticlesBannerSlide.ctaTitle,
			picture: {
				pictureDesktop,
				pictureMobile,
				alt: mainAlt,
			},
			articleTitle: strapiArticlesBannerSlide.articleTitle,
			articleBody: strapiArticlesBannerSlide.articleBody,
			ctaLinkHref: strapiArticlesBannerSlide.ctaLinkHref,
			articleCategoryTitle:
				strapiArticlesBannerSlide.articleCategoryTitle || '',
		}
	})

export const convertToStoresBanner = (
	strapiStoresBannerSlides: StrapiStoresBannerSlide[]
) =>
	strapiStoresBannerSlides.map((strapiStoresBannerSlide) => {
		const mainAlt = strapiStoresBannerSlide.desktopPicture.alternativeText || ''

		const pictureDesktop = {
			height: strapiStoresBannerSlide.desktopPicture.height || 0,
			width: strapiStoresBannerSlide.desktopPicture.width || 0,
			src: getExpectedImgixURL(strapiStoresBannerSlide.desktopPicture.url),
			alt: strapiStoresBannerSlide.desktopPicture.alternativeText || '',
		}

		const pictureMobile = {
			height: strapiStoresBannerSlide.mobilePicture.height || 0,
			width: strapiStoresBannerSlide.mobilePicture.width || 0,
			src: getExpectedImgixURL(strapiStoresBannerSlide.mobilePicture.url),
			alt: strapiStoresBannerSlide.mobilePicture.alternativeText || '',
		}

		return {
			id: strapiStoresBannerSlide.id,
			picture: {
				pictureDesktop,
				pictureMobile,
				alt: mainAlt,
			},
			storeName: strapiStoresBannerSlide.storeName,
			storeTitle: strapiStoresBannerSlide.storeTitle,
			linkCtas: strapiStoresBannerSlide.linkCtas.map((cta) => {
				return {
					text: cta.text,
					href: cta.linkHref,
					type: cta.type,
				}
			}),
			details: strapiStoresBannerSlide.details,
		}
	})

export const convertToNewsBanner = (
	strapiNewsBannerItems: StrapiNewsBannerItems[]
) =>
	strapiNewsBannerItems.map((strapiNewsBannerItem) => {
		return {
			id: strapiNewsBannerItem.id,
			newsLogoHeight: strapiNewsBannerItem.newsOrgLogo.height || 0,
			newsLogoWidth: strapiNewsBannerItem.newsOrgLogo.width || 0,
			newsLogoSrc: getExpectedImgixURL(strapiNewsBannerItem.newsOrgLogo.url),
			newsLogoAlt:
				strapiNewsBannerItem.newsOrgLogo.alternativeText ||
				strapiNewsBannerItem.newsOrgName ||
				'Logo Image',
			linkHref: strapiNewsBannerItem.linkHref,
		}
	})

export const convertToIframeBanner = (
	strapiIframeBannerItem: StrapiIframeBannerItem
) => {
	return {
		src: strapiIframeBannerItem.iframe.src,
		title: strapiIframeBannerItem.iframe.title,
		mobileHeight: strapiIframeBannerItem.iframe.mobileHeight,
		desktopHeight: strapiIframeBannerItem.iframe.desktopHeight,
	}
}

export const convertToInfoBanner = (
	strapiInfoBannerItem: StrapiInfoBannerItem
) => {
	let iframe

	if (strapiInfoBannerItem.iframe) {
		iframe = {
			src: strapiInfoBannerItem.iframe.src,
			title: strapiInfoBannerItem.iframe.title,
			mobileHeight: strapiInfoBannerItem.iframe.mobileHeight,
			desktopHeight: strapiInfoBannerItem.iframe.desktopHeight,
		}
	}

	let picture

	if (
		strapiInfoBannerItem.desktopPicture &&
		strapiInfoBannerItem.mobilePicture &&
		!strapiInfoBannerItem.desktopPicture.hasOwnProperty('data') &&
		!strapiInfoBannerItem.mobilePicture.hasOwnProperty('data')
	) {
		const mainAlt = strapiInfoBannerItem.desktopPicture.alternativeText || ''

		const pictureDesktop = {
			height: strapiInfoBannerItem.desktopPicture.height || 0,
			width: strapiInfoBannerItem.desktopPicture.width || 0,
			src: getExpectedImgixURL(strapiInfoBannerItem.desktopPicture.url),
			alt: strapiInfoBannerItem.desktopPicture.alternativeText || '',
		}

		const pictureMobile = {
			height: strapiInfoBannerItem.mobilePicture.height || 0,
			width: strapiInfoBannerItem.mobilePicture.width || 0,
			src: getExpectedImgixURL(strapiInfoBannerItem.mobilePicture.url),
			alt: strapiInfoBannerItem.mobilePicture.alternativeText || '',
		}

		picture = {
			pictureDesktop,
			pictureMobile,
			alt: mainAlt,
		}
	}

	return {
		categoryTitle: strapiInfoBannerItem.categoryTitle || '',
		title: strapiInfoBannerItem.title,
		body: strapiInfoBannerItem.body,
		picture,
		iframe,
		reverseDesktopLayout: strapiInfoBannerItem.reverseDesktopLayout,
	}
}

export const convertToCollectionBanner = (
	strapiCollectionBannerItem: StrapiCollectionBannerItem
) => {
	const collectionItems = strapiCollectionBannerItem.items.map((item) => {
		return {
			id: item.id,
			name: item.name,
			nameColor: item.nameColor || '',
			imageHeight: item.image.height || 0,
			imageWidth: item.image.width || 0,
			imageSrc: getExpectedImgixURL(item.image.url),
			imageAlt: item.image.alternativeText || '',
			ctaLinkHref: item.ctaLinkHref,
			ctaTitle: item.ctaTitle,
			ctaTitleColor: item.ctaTextColor || '',
			ctaBorderColor: item.ctaBorderColor || '',
		}
	})

	return {
		title: strapiCollectionBannerItem.collectionTitle,
		collectionItems,
	}
}
